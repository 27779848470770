import { Response } from '@/types';
import { getUserStoreInfo } from '@/utils/helpers';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import http from './http';
import { useMenuStore } from './menu';
import { use } from 'echarts';



export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}
export interface Account {
  username: string;
  avatar: string;
  gender: number;
}

export type TokenResult = {
  userId: number;
  token: string;
  auth:string;
  timestamp: string;
  realname:string;
  userType:number;
};
export type CaptchaResult = {
  code: number;
  data: string;
}
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true,
      accountType:0,
    };
  },
  actions: {
    async login(username:string,password:string,verCode:string) {
      return http
      .request<TokenResult, Response<TokenResult>>('/user/login', 'post_json', { username, password,verCode })
      .then(async (response) => {
        if (response.code === 200) {
          this.logged = true;
           // 设置请求
          http.setAuthorization(`${response.data.auth}`,360000);
          Cookies.set('timestamp',response.data.timestamp);
          // 缓存数据
          localStorage.setItem("userInfo",JSON.stringify(response.data));
          // 获取用户菜单
          await useMenuStore().getMenuList();
          return response.data;
        } else {
          return Promise.reject(response.data);
        }
      })
   
     
    },
    async hrefLogin() {
      const router = useRouter();
      // 获取token
      const queryToken = router.currentRoute.value.query.auth;
      // 查看用户是否登录
      if (queryToken) {
        const getUserType = await http
          .request<TokenResult, Response<TokenResult>>('/user/getUserType', 'get', { userId:router.currentRoute.value.query.userId })
          .then((response) => {
            return response.data;
          });
        // 验证token
        const userInfo = {
          userId: router.currentRoute.value.query.userId,
          token: router.currentRoute.value.query.auth,
          timestamp: String(router.currentRoute.value.query.timestamp),
          realname: router.currentRoute.value.query.realname,
          userType:getUserType.userType
        };
        // 设置请求
        http.setAuthorization(`${userInfo.token}`, 36000);
        Cookies.set('timestamp', userInfo.timestamp);
        // 缓存数据
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        // 获取用户的菜单
        useMenuStore().getMenuList();
      }
      
      return;
   
     
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem('stepin-menu');
        // 缓存数据
        localStorage.setItem("userInfo","{}");
        http.removeAuthorization();
        this.logged = false;
        resolve(true);
      });
    },
    // 获取用户信息
    profile() {
      return new Promise<TokenResult>((resolve) => {
        resolve( getUserStoreInfo());
      });
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
    // 获取验证码
    async getCaptcha() {
      return http
      .request<CaptchaResult, Response>('/user/captcha', 'BLOB')
      .then(async (response) => {
        if (response.status === 200) {
          return response.data;
        }
        return Promise.reject(response);
      })
    }
  },
  getters:{
    userType():any{
      if (this.accountType === 0) {
        const userInfo = getUserStoreInfo();
        this.accountType = userInfo.userType;
      }
      return this.accountType;
    }
  }
});
