import { Plugin } from 'vue';
// 引入空数据组件
import emptyData from "../components/common/empty.vue";

const empty:Plugin = {
    install(app) {
        app.component('empty', emptyData);
    }

}
export default empty; 