
import pinia from '@/store';
import stepin from 'stepin/es';
import { createApp,onMounted,onUnmounted,ref } from 'vue';
import globalComponent from "@/plugins/globalComponent";
// 生产打包时可去除 ant-design-vue/dist/antd.variable.less 的引用。
// 开发引入此包是为了加载优化，防止首次打开页面过慢
import 'ant-design-vue/dist/antd.variable.less';
import 'stepin/es/style';
import '@/theme/index.less';
import "animate.css";
// import 'default-passive-events
// 导入动画;
import { AuthPlugin, IconfontPlugin } from '@/plugins';

import App from './App.vue';
import router from '@/router';
// 全局使用datav
import DataVVue3Plugin from '@kjgl77/datav-vue3';
// 全局汉化
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import BaiduMap from 'vue-baidu-map-3x';


dayjs.locale('zh-cn');

// 定义根字体大小的初始值
const baseFontSize = 16;
// 设计稿宽度，用于计算缩放比例
const designWidth = 375;

// 创建响应式数据存储当前的 fontSize
const fontSize = ref(baseFontSize);

// 动态设置根元素字体大小的函数
const setRootFontSize = () => {
  const scale = window.innerWidth / designWidth;
  fontSize.value = baseFontSize * Math.min(scale, 2); // 防止字体过大
  document.documentElement.style.fontSize = `${fontSize.value}px`;
};

// 在组件挂载后执行
onMounted(() => {
  setRootFontSize();
  // 添加窗口大小变化的监听器
  window.addEventListener('resize', setRootFontSize);
});

// 组件卸载前移除监听器，防止内存泄漏
onUnmounted(() => {
  window.removeEventListener('resize', setRootFontSize);
});

const app = createApp(App);

app.use(globalComponent);
// app.use(BaiduMap, {
//   ak: "AhJyrRUfVe7ulzkXESTCWuW8Cmq4ytvb"  //使用自己的ak
// })
app.use(DataVVue3Plugin);
app.use(pinia);
app.use(router);
app.use(stepin, { router });
app.use(AuthPlugin, { action: 'disable' });
// iconfont 插件。url为你的 iconfont 图标资源地址（你的iconfont 仓库可获取此地址）
app.use(IconfontPlugin, { url: '//at.alicdn.com/t/c/font_3805284_ulvha6ct7d.js' });
app.config.errorHandler = function (err) {
  console.error('未捕获的异常，', err);
};
app.mount('#stepin-app');
