<template>
<div class="w-full h-full box" :class="extraCss" :style="extraStyle">
    <img :src="iconUrl" alt="" :style="{width:`${imgSize[0]}px`,height:`${imgSize[1]}px`}">
    <div :class="theme">{{text}}</div>
</div>
</template>
<script setup lang="ts">
import { ref } from 'vue';


const props = defineProps({
    icon:{
        type: String,
        default: ""
    },
    text: {
        type: String,
        default: ''
    },
    extraCss:{
        type: String,
        default: 'flex flex-col justify-center items-center'
    },
    extraStyle: {
        type: String,
        default: ''
    },
    imgSize:{
        type: Array<Number>,
        default: [160,141]
    },
    theme:{
        type: String,
        default: 'light'
    }
    
})
const iconUrl = ref(props.icon);
if (!iconUrl.value) {
    iconUrl.value = props.theme == 'light' ? new URL("@/assets/empty_icon.png", import.meta.url).href:new URL("@/assets/image/empty_dark.png", import.meta.url).href
}
</script>
<style lang="less" scoped>
.box {
    font-size: 14px;
}
.dark {
    color: #98B0FF;
}
.default {
    color: rgba(255, 255, 255, 0.9);
}
</style>
