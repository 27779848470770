<template>
  <a-config-provider :locale="zhCN">
    <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
      <stepin-view system-name="智能监测管理系统" logo-src="/logo.png" :class="`${contentClass}`" :user="user"
        :navMode="navigation" :useTabs="useTabs" :themeList="themeList" v-model:show-setting="showSetting"
        v-model:theme="theme" @themeSelect="configTheme">

        <template #themeEditorTab>
          <a-tab-pane tab="其它" key="other">
            <Setting />
          </a-tab-pane>
        </template>
      </stepin-view>
    </ThemeProvider>
    <login-modal @success="uploadUser" :unless="['/login']" />
  </a-config-provider>
</template>

<script lang="ts" setup>
import avatar from '@/assets/avatar.png';
import { LoginModal } from '@/pages/login';
import { storeToRefs, useAccountStore, useMenuStore, useSettingStore } from '@/store';
import { configTheme, defaultTheme, themeList } from '@/theme';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { ThemeProvider } from 'stepin';
import { useThemeStore } from 'stepin/es/theme-provider';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import Setting from './components/setting';

const { logout, profile } = useAccountStore();
const showSetting = ref(false);
const router = useRouter();
// 获取用户信息
const userInfo = profile().then((res) => {
  const userInfo = res;
  if (res.realname) {
    user.name = userInfo.realname;
  }

})
const uploadUser = (user) => {
  profile().then((res) => {
    const userInfo = res;
    if (res.realname) {
      user.name = userInfo.realname;
    }

  })
};

// 排除登录页
if (router.currentRoute.value.path !== '/login') {
  useMenuStore().getMenuList();
}
// 获取用户信息

const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
const themeConfig = defaultTheme.config;
const { setPrimaryColor, setRadius } = useThemeStore();
setPrimaryColor({ DEFAULT: '#3D4687' });
setRadius({ 'radius-sm': "2px" });
const user = reactive({
  name: '管理员',
  avatar: avatar,
  menuList: [
    // { title: '个人中心', key: 'personal', icon: 'UserOutlined', onClick: () => router.push('/profile') },
    // { title: '设置', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
    { type: 'divider' },
    {
      title: '退出登录',
      key: 'logout',
      icon: 'LogoutOutlined',
      onClick: () => logout().then(() => router.push('/login')),
    },
  ],
});
</script>

<style lang="less">
.stepin-view {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #bfbfbf;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bfbfbf;

    &:hover {
      background-color: #bfbfbf;
    }
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background: theme('backgroundColor.layout');
  }
}

html {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
  @media screen and (max-height:769px) { 
    overflow-y: scroll;
  }
}

.stepin-img-checkbox {
  @apply transition-transform;

  &:hover {
    @apply scale-105~"-translate-y-[2px]";
  }

  img {
    @apply shadow-low rounded-md transition-transform;
  }
}
</style>
