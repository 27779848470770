/**
 * 初始化目标值为 undefined 的属性
 * @param target 目标对象
 * @param dft 默认值对象
 */
export function initUndefined<T extends Record<string, any>, K extends keyof T>(target: T, dft: Required<Pick<T, K>>) {
  (Object.keys(dft) as K[]).forEach((key) => (target[key] = target[key] ?? dft[key]));
}

/**
 * 获取用信息
 */
export function getUserStoreInfo() {
  return JSON.parse(localStorage.getItem("userInfo") || "{}");
}
/**
 * 将描述转为符号
 */
export function standardParamToString(params:string) {
    if (params == 'gt') {
      return '>';
    }
    if (params == 'lt') {
      return '<';
    }
    if (params == 'eq') {
      return '=';
    }
    if (params == 'ge') {
      return '>=';
    }
    if (params == 'le') {
      return '<=';
    }
    return '';
}
/**
 * 将name转中文
 */
export function standardNameToString(name:string) {
   if(name =='normal'){
     return '正常';
   }
    if(name =='abnormal'){
      return '异常';
    }
    return '';
}
