import { isResponse } from '@/types';
import createHttp from '@/utils/axiosHttp';
import { message } from 'ant-design-vue';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import NProgress from 'nprogress';
import { useRouter } from 'vue-router';

const router = useRouter();

const http = createHttp({
  timeout: 10000,
  baseURL: 'api',
  withCredentials: true,
  xsrfCookieName: 'token',
  xsrfHeaderName: 'token',
});
const isAxiosResponse = (obj: any): obj is AxiosResponse => {
  return typeof obj === 'object' && obj.status && obj.statusText && obj.headers && obj.config;
};


// progress 进度条 -- 开启
http.interceptors.request.use((req: AxiosRequestConfig) => {
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  return req;
});

// 解析响应结果
http.interceptors.response.use(
  (rep: AxiosResponse<any>) => {
    const { data } = rep;
    if (rep.request.responseType == 'blob' || rep.request.responseType === 'arraybuffer') {
      return rep;
    }
    
    if (isResponse(data)) {
      return data.code === 0 ? data : Promise.reject(data);
    }  
    if (data.code == 401) {
      http.removeAuthorization();
      // 跳转到登陆
      message.error({
        content: '登录失效，请重新登录',
        duration: 1.5 
      })
      router.push('/login');
    }
    // code >0
    if (data.code) {
      return Promise.reject({ message: rep.statusText, code: rep.status, data:data.data });
    }
    // code = 0 token失效
  ;

    if ("code" in data && data.code != 200)  {
      return Promise.reject({message: data.msg, code: data.code,data:data});
    }
    return Promise.reject({message: rep.statusText, code: rep.status,data:data});
    
  },
  (error) => {
    if (error.response && isAxiosResponse(error.response)) {
      message.error({
        content: error.response.statusText,
        duration: 1.5 
      })
      return Promise.reject({
        message: error.response.statusText,
        code: error.response.status,
        data: error.response.data,
      });
    }
   
    message.error({
      content: error.message,
      duration: 1.5 
    })
    return Promise.reject(error);
  }
);

// progress 进度条 -- 关闭
http.interceptors.response.use(
  (rep) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    return rep;
  },
  (error) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    return error;
  }
);

export default http;
